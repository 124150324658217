/* Apply styles to MiniMap and Controls */
.dark .react-flow__controls {
  background-color: rgb(24 24 27 / var(--tw-bg-opacity)) !important; /* zinc-900 */
}

/* Base Button Styles in Dark Mode */
.dark .react-flow__controls-button {
  background-color: rgb(24 24 27 / var(--tw-bg-opacity)) !important; /* zinc-900 */
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important; /* white */
  border-bottom: 1px solid rgb(39 39 42 / var(--tw-border-opacity)) !important; /* zinc-800 */
  transition: background-color 0.2s ease-in-out; /* Smooth transition */
}
.dark .react-flow__controls-button:hover {
  background-color: rgb(17 17 20 / var(--tw-bg-opacity)) !important; /* Darker zinc-950 */
}
.dark .react-flow__controls-button svg {
  fill: white !important;  /* Ensures white icons in dark mode */
  stroke: white !important; /* Applies to outlined icons */
}